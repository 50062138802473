import PropTypes from 'prop-types';
// Utils & misc
import { cn } from 'utils';
// Style
import styles from './Button.module.css';
// Components
import { Picto, availablePictos } from '../Picto/Picto';

function Button({
  onClick,
  isSubmit,
  isLoading,
  icon,
  iconPosition,
  children,
  className,
  disabled,
  color,
  outline,
}) {
  return (
    <button
      className={cn([
        styles.button,
        isLoading && styles.isLoading,
        outline && styles.outline,
        className,
      ])}
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <span className={cn([styles.inside, styles[color], styles[iconPosition]])}>
        {isLoading ? <Picto icon="loader" className={styles.loader} /> : null}
        {!isLoading && icon ? <Picto icon={icon} className={styles.icon} /> : null}
        {children}
      </span>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
  isSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOf(Object.keys(availablePictos)),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  onClick: undefined,
  color: 'primary',
  isSubmit: false,
  isLoading: false,
  icon: null,
  iconPosition: 'left',
  className: null,
  disabled: false,
  outline: false,
};

export default Button;
