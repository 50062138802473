import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useResponsiveQueries = () => {
  const [mounted, setMounted] = useState(false);

  const mobileQuery = useMediaQuery({ maxWidth: 768 });
  const tabletQuery = useMediaQuery({ maxWidth: 1024 });
  const laptopQuery = useMediaQuery({ maxWidth: 1200 });
  const smallDesktopQuery = useMediaQuery({ maxWidth: 1400 });
  const desktopQuery = useMediaQuery({ maxWidth: 1440 });

  useEffect(() => {
    setMounted(true);
  }, []);

  const isMobile = mounted && mobileQuery;
  const isTablet = mounted && tabletQuery;
  const isLaptop = mounted && laptopQuery;
  const isDesktop = mounted && desktopQuery;
  const isSmallDesktop = mounted && smallDesktopQuery;

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isSmallDesktop,
  };
};

export default useResponsiveQueries;
